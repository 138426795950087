<template>
  <section class="RequestsTable" :style="{minWidth: '800px'}">
    <div class="new_request">
      <Button
          v-if="$store.getters['user/permissions'].includes('accreditationRequests:createAPI')"
          type="primary"
          @click="redirectToRequestAccreditation()"
      >
        Submit new request
      </Button>
    </div>

    <div class="RequestsTable__filters filters row mb-0">
      <div class="col-xl-3 col-md-6 col-12">
        <InputDropdown
            placeholder="Select matchday"
            :allow-empty="false"
            trackLabel="optionLabel"
            :options="matchDaysList"
            v-model="matchDay"
        />
      </div>
      <div class="col-xl-3 col-md-6 col-12">
        <InputDropdown
            placeholder="Select match"
            :allow-empty="false"
            trackLabel="name"
            :options="matchesList"
            v-model="match"
        />
      </div>

      <div class="col-xl-6 col-12">
        <InputSearch placeholder="Search by Name, Nationality, Passport ID, Designation"
                     @searchkey="searchAccreditation" @clearFilters="clearFilters"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-3">
        <Button class="button-delete-bulk" type="outline-dark"
            :disabled="this.selectedRows.length === 0"
            @click="requestBulkDelete()"
            icon="BIN_OUTLINE"> Delete requests
        </Button>
      </div>
    </div>

    <Table
        :collection="accreditationRequestsList"
        :columns="tableColumns"
        @on-sort="onSort"
    >
      <template v-slot:table-cell-requestDate="slot">
        {{ moment(slot.data).format('DD.MM.YYYY') }}
      </template>
      <template v-slot:table-cell-organization="slot">
        {{ getOrganization(slot) || '--' }}
      </template>
      <template v-slot:table-cell-gender="slot">
        {{ slot.model.person ? slot.model.person.gender : '--' }}
      </template>
      <template v-slot:table-cell-dob="slot">
        {{ slot.model.person ? slot.model.person.birthDate : '--' }}
      </template>
      <template v-slot:table-cell-nationality="slot">
        {{ getNationality(slot) || '--' }}
      </template>
      <template v-slot:table-cell-accreditationPhoto="slot">
        <span class="with-word-break">
          {{ (slot.model.person && slot.model.person.accreditationPhoto) ? getPhotoName(slot.model.person) : '--' }}
        </span>
      </template>
      <template v-slot:table-cell-event="slot">
        {{ upperFirst(slot.data) }}
      </template>
      <template v-slot:table-cell-requestType="slot">
        {{ upperFirst(slot.data) }}
      </template>
      <template v-slot:table-cell-status="slot">
        <span :style="{ color: getStatusColor(slot.data) }">{{
            slot.data === 'in_review' ? 'In Review' : upperFirst(slot.data)
          }}</span>
      </template>
      <template v-slot:table-cell-actions="slot">
        <Button
            class="RequestsTable__button-delete"
            @click="requestDelete(slot.model)"
            icon="BIN_OUTLINE"
            type="round"
            v-if="slot.model.status === 'requested'"
        />
        <Button
            form="round"
            type="primary"
            icon="RIGHT_OUTLINE"
            @click.native="redirectToDetails(slot.model.id, slot.model)"
        />
      </template>
      <template v-slot:table-cell-rowSelect="slot">
        <input type="checkbox" class="TeamTable__checkbox-select rowSelect"
               v-model="selectedRows"
               :value="slot.model.id"
               :disabled="slot.model.status!=`requested`" />
      </template>
      <template v-slot:table-cell-venues="slot">
        <span v-if="slot.model.allVenues">
          All venues
        </span>
        <span v-else-if="Array.isArray(slot.data)">
          {{ slot.data.slice(0, venuesLimit).map(venue => venue.localTitle).join(venuesGlue) }}
          <span
            class="text-primary pointer"
            :id="'venuesTooltip' + slot.model.id"
            @click="$root.$emit('bv::hide::tooltip')"
            v-if="slot.data.length > venuesLimit"
          >
            more
          </span>
          <b-tooltip
            :target="'venuesTooltip' + slot.model.id"
            triggers="click"
            placement="bottom"
            variant="primary"
          >
            {{ slot.data.slice(venuesLimit).map(venue => venue.localTitle).join(venuesGlue) }}
          </b-tooltip>
        </span>
      </template>
      <template v-slot:table-header-bulk="slot">
        <input type="checkbox" class="checkAll" v-model="checkAll"  />
      </template>
      <template v-slot:table-cell-person.passportPhoto="slot">
        <Thumbnail :value="slot.model.person" type="passportPhoto" />
      </template>
      <template v-slot:table-cell-person.accreditationPhoto="slot" >
        <Thumbnail :value="slot.model.person" type="accreditationPhoto" />
      </template>
    </Table>

    <TableControls @setLimit="updateLimit($event)" @selectPage="updatePage($event)"
                   :count="count" :limit="limit" :offset="offset"/>

    <vue-confirm-dialog class="confirm-dialog"></vue-confirm-dialog>
  </section>
</template>

<script>
import upperFirst from 'lodash.upperfirst';
import moment from "moment";

import Table from '@/components/Table/Table.component';
import TableControls from "@/components/Table/TableControls.component";
import InputDropdown from "@/components/Inputs/InputDropdown.component";
import InputSearch from "@/components/Inputs/InputSearch.component";
import Button from "@/components/Button/Button.component";
import Thumbnail from "@/components/Image/Thumbnail.component";

import RouterNames from '@/router/route.names';

import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";
import get from "lodash.get";
import {
  DtoApiDeleteAccreditationRequest
} from "../../../classes/dto/api/accreditations/dto.api.delete-accredittaion.request";

export default {
  name: 'DDMCAF-RequestsTable',
  components: {
    Table,
    Button,
    InputDropdown,
    InputSearch,
    TableControls,
    Thumbnail,
  },
  data: () => ({
    matchDay: null,
    match: null,
    screenWidth: window.innerWidth,
    tableColumns: [],
    selectedRows: [],
    filter: {
      matchDayId: null,
      matchId: null,
      searchText: '',
    },
    venuesLimit: 3,
    venuesGlue: '; ',
  }),
  watch: {
    matchDay(matchDay) {
      if (matchDay) {
        this.filter.matchDayId = matchDay.matchDayId;
        this.getMatches(matchDay)
      } else {
        this.filter.matchDayId = null;
      }
      if (this.match) {
        this.match = null;
      } else {
        this.getAccreditations(this.filter)
      }
    },
    match(match) {
      if (match) {
        this.filter.matchId = match.matchId;
      } else {
        this.filter.matchId = null;
      }
      this.getAccreditations(this.filter);
    },
    account({organizationId}) {
      this.pageMemo = this.page;
      this.page = 1;
      this.getAccreditations(organizationId);
    },
  },
  computed: {
    account() {
      return get(this, '$store.state.user.account', []);
    },
    pageComputed() {
      return this.request.$loading
          ? this.pageMemo
          : this.page;
    },
    accreditationRequestsList() {
      return get(this, '$store.state.accreditationsList.accreditationRequestsList');
    },
    matchDaysList() {
      return get(this, '$store.state.event.matchDaysList');
    },
    matchesList() {
      return get(this, '$store.state.event.matchesList');
    },
    season() {
      return get(this, '$store.state.event.selectedSeason', null)
    },
    isTournamentMode() {
      return Boolean(this.season?.isTournamentMode);
    },
    limit: {
      get: function () {
        return get(this, '$store.state.accreditationsList.limit');
      },
      set: function (newVal) {
        this.$store.state.accreditationsList.limit = newVal;
      }
    },
    accreditationsListMeta: {
      get: function () {
        return get(this, '$store.state.accreditationsList.accreditationListMetaData');
      },
    },
    count() {
      return this.accreditationsListMeta ? this.accreditationsListMeta.count : 0;
    },
    offset() {
      return this.accreditationsListMeta ? this.accreditationsListMeta.offset : 0;
    },
    page: {
      get: function () {
        return get(this, '$store.state.accreditationsList.page');
      },
      set: function (newVal) {
        this.$store.state.accreditationsList.page = newVal;
      }
    },
    pages: {
      get: function () {
        return get(this, '$store.state.accreditationsList.pages');
      },
      set: function (newVal) {
        this.$store.state.accreditationsList.pages = newVal;
      }
    },
    orderBy: {
      get: function () {
        return get(this, '$store.state.accreditationsList.orderBy');
      },
      set: function (newVal) {
        this.$store.state.accreditationsList.orderBy = newVal;
      }
    },
    orderByDirection: {
      get: function () {
        return get(this, '$store.state.accreditationsList.orderByDirection');
      },
      set: function (newVal) {
        this.$store.state.accreditationsList.orderByDirection = newVal;
      }
    },
    checkAll: {
      get: function () {
        return this.selectedRows.length > 0;
      },
      set: function (value) {
        var checked = [];
        if (value) {
          this.accreditationRequestsList.models.forEach(function (model) {
            if(model.status === 'requested'){
              checked.push(model.id);
            }
          });
        }
        this.selectedRows = checked;
      }
    }
  },
  methods: {
    async requestDelete(accreditation) {
      this.$confirm(
          {
            message: `Are you sure you want to delete this accreditation?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                new DtoApiDeleteAccreditationRequest()
                    .$deleteAccreditation([accreditation.id], this.$store.state.user.account.id)
                    .then(() => {
                        this.getAccreditations();
                })
              }
            }
          })

    },
    async requestBulkDelete() {
      this.$confirm(
          {
            message: `Are you sure you want to delete selected requests?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                new DtoApiDeleteAccreditationRequest()
                    .$deleteAccreditation(this.$data.selectedRows, this.$store.state.user.account.id)
                    .then(() => {
                      this.selectedRows = [];
                      this.getAccreditations()
                    })
              }
            }
          })
    },
    getPhotoName(person) {
      return `${person.firstName.toLowerCase()}_${person.lastName.toLowerCase()}`;
    },
    moment,
    upperFirst,
    getOrganization(data) {
      return get(data, 'model.organization.name');
    },
    getNationality(data) {
      let value = get(data, 'model.person.nationality.isoCodea2');
      return value ? value.toUpperCase() : value;
    },
    getStatusColor(status) {
      return ({
        approved: '#7fcc90',
        rejected: '#f68476',
        in_review: '#fac991',
        requested: '#a8a8a8',
        draft: '#a8a8a8'
      })[status] || '#a8a8a8';
    },
    redirectToDetails(request) {
      if (!request) return;
      this.$router.push({
        name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequestsRoutes.AccreditationRequestDetails,
        params: {request}
      })
    },
    redirectToRequestAccreditation() {
      this.$store.commit('accreditationRequest/clearState')
      this.$router.push({name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequest})
    },
    onSort(event) {
      this.orderBy = event.sort;
      this.orderByDirection = event.sortDirection;
      this.getAccreditations();

    },
    updateLimit(limit) {
      this.limit = limit;
      this.page = 1;
      this.getAccreditations();
    },
    updatePage(page) {
      this.page = page;
      this.getAccreditations();
    },
    getAccreditations(data) {
      this.$store.dispatch('accreditationsList/getAccreditations', data);
    },
    getMatches(data) {
      this.$store.dispatch('event/getMatches', {...data, withRequestsFor: 'accreditation_request'});
    },
    clearFilters() {
      this.filter.searchText = '';
      if (this.matchDay) {
        this.matchDay = null;
      } else if (this.match) {
        this.match = null;
      } else {
        this.getAccreditations(this.filter);
      }
    },
    setTableColumns() {
      this.tableColumns = new TableColumnsCollection([
        new TableColumnModel('rowSelect', '', '44px'),
        new TableColumnModel('requestDate', 'Date', '70px', 'created_at'),
        new TableColumnModel('event', 'Event', '80px', 'event.name'),
        new TableColumnModel('firstName', 'First Name', '100px', 'person.firstName'),
        new TableColumnModel('lastName', 'Last Name', '100px', 'person.lastName'),
        ...(this.isTournamentMode ? [new TableColumnModel('venues', 'Venues', '150px')] : []),
        new TableColumnModel('person.passportNumber', 'Passport/ID NO.', '125px', 'person.passportNumber'),
        new TableColumnModel('nationality', 'Nationality', '100px', 'nationality.isoCodea2'),
        new TableColumnModel('gender', 'Gender', '65px', 'person.gender'),
        new TableColumnModel('category', 'Category', '100px', 'category.name'),
        new TableColumnModel('subCategory', 'Designation', '120px', 'subcategory.name'),
        //new TableColumnModel('accredType', 'SAD type', '100px', 'accredType.name'),
        new TableColumnModel('person.accreditationPhoto', 'Accreditation Photo', '160px'),
        new TableColumnModel('person.passportPhoto', 'Passport/ID Photo', '140px'),
        new TableColumnModel('status', 'Status', '80px', 'status'),
        new TableColumnModel('actions', '', '80px'),
      ])
    },

    searchAccreditation(value) {
      this.filter.searchText = value;
      this.getAccreditations(this.filter);
    }
  },
  created() {
    this.getAccreditations();
    this.setTableColumns();
  },
}
</script>
<style lang="scss">
@import "src/styles/main.scss";

.confirm-dialog {
  .vc-btn:last-child {
    color: $danger-color;
  }

  .vc-btn:first-child {
    color: $primary-color;
  }
}

.RequestsTable {

  .Table__table {
    table-layout: fixed;
  }

  .Table__table-cell-container {
    justify-content: space-between;
  }

  .button-component__icon {
    font-size: 28px !important;
  }

  &__button-delete {
    height: auto;
    background: none;

    .button-component__icon {
      color: $danger-color !important;
      transform: none;
    }
  }

  .button-delete-bulk:not(.disabled) .button-component__icon {
    color: $danger-color;
  }

  &__filters-input-wrapper {
    display: flex;
  }
  .new_request {
    margin-bottom: 20px;
  }
  .input-dropdown {
    display: flex;
    > div {
      width: 50%;
    }
  }
}

.pointer {
  cursor: pointer;
}

.tooltip-inner {
  max-width: 400px;
}
</style>
