<template>
  <section class="requests-list">
    <SpaceDescription>
      <Headline :size="1">Accreditation Requests</Headline>
    </SpaceDescription>

    <RequestsTable/>
  </section>
</template>

<script>
import SpaceDescription from '@/components/SpaceDescription/SpaceDescription.component';
import Headline from '@/components/Headline/Headline.component';
import RequestsTable from '@/components/Table/tables/RequestsTable.component';
import RouterNames from "@/router/route.names";

export default {
  name: "RequestsList",
  components: {
    SpaceDescription,
    Headline,
    RequestsTable
  },
  computed: {
    permissions() {return this.$store.getters['user/permissions']}
  },
  watch: {
    permissions() {this.checkPermissions()}
  },
  created() {
    this.checkPermissions();
    this.$store.dispatch('event/getMatchDays')
  },
  methods: {
    checkPermissions() {
      const permitted = this.$store.getters['user/haveAccessToAccreditation'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
  },
}
</script>


<style lang="scss">
.requests-list {
   &__filters {
     display: flex;
     justify-content: space-between;
     margin-bottom: 30px;

     &-input-wrapper, &-btn-wrapper {
       display: flex;
       > .InputDropdown:first-child {
         margin-right: 30px;
       }
       > .button-component:first-child {
         margin-right: 30px;
       }
     }
   }
}
</style>
